import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const CasCreateApplication: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">Step 2: Create a CAS SAML Application</Title>

    <Text>
      Create a SAML application in your CAS instance. You'll need the following
      Assertion Consumer Service (ACS) URL. The ACS URL is the location CAS will
      redirect its authentication response to.
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Confirm label="I've created a SAML application." onClick={onNextStep} />
  </Article>
);

export const CasProvideSpIdentity: React.FC<Readonly<ConnectionStepProps>> = ({
  appName,
  connection,
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">Step 3: Provide the SP Entity ID</Title>

    <Text>
      The Service Provider Entity ID is a unique value which represents{' '}
      {appName} within CAS. Provide the value below to your CAS SAML application
      settings.
    </Text>

    <CopyInput
      label="Copy the SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Confirm
      label="I've provided the SP Entity ID to CAS."
      onClick={onNextStep}
    />
  </Article>
);

export const CasUploadMetadata: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://mycasserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mycasserver.com/..."
    metadataUrlPlaceholder="https://"
    stepTitle="Step 4: Upload Identity Provider Metadata"
  >
    <Text>
      To connect with your CAS instance, please upload the CAS SAML application
      metadata.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Upload the IdP metadata URL from your SAML settings. Alternatively, you
      can manually configure the connection by providing the SSO URL, IdP Issuer
      and X.509 Certificate.
    </Text>
  </MetadataUrlStep>
);

export const CasConfigureClaims: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">Step 5: Configure your SAML Claims</Title>

    <Text>
      The final step is to confirm that user attributes map to the following
      SAML claims.
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've finished configuring the SAML claims."
      onClick={onNextStep}
    />
  </Article>
);
